.Prompt {
  position: fixed;
  z-index: 6000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s;

  .prompt-inner {
    position: absolute;
    border-radius: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    opacity: 0;
    transition: all 0.2s;

    .prompt-header {
      position: relative;
      color: white;
      flex: 0 0 88px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url("../assets/backgrounds/orange.jpg");
      background-size: cover;

      .prompt-title {
        flex-grow: 1;
        padding: 24px;
      }
      .cross {
        position: absolute;
        top: 0;
        right: 0;
        padding: 12px;
        box-sizing: content-box;
        width: 64px;
        height: 64px;
        background: none;
        border: none;
        svg {
          polygon {
            fill: white;
            stroke: none;
          }
          rect {
            fill: none;
            stroke: none;
          }
          width: 100%;
          height: 100%;
        }
      }
    }

    .prompt-body {
      position: relative;
      background: white;
      flex-grow: 1;

      .prompt-body-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 10;
      }

      .sketch {
        opacity: 0.1;
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        svg {
          transform: translateY(10%);
          width: 100%;
          height :100%;
        }
      }
    }
  }

  &.entering {
    opacity: 1;
    .prompt-inner {
      opacity: 1;
    }
  }
}
