.SearchResultPanel {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 590px;
  z-index: 120;

  .Close {
    position: absolute;
    left: 574px;
    top: 24px;
  }
}
