.CropPin {
  transform: scale(0.1);
  transition: all 0.2s;
  .crop {
    width: 42px;
    height: 42px;
    background: white;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    border: 2px solid white;
    box-sizing: border-box;
    svg {
      width: 32px;
      height: 32px;
      path {
        stroke: white;
        stroke-width: 2px;
        //fill: white;
      }
    }
  }
}
