.CropsPicker {
  .crops-filter-tooltip {
    position: fixed;
    bottom: 48px;
    left: 200px;
    color: white;
    z-index: 30;
    text-shadow: 1px 1px 3px rgba(0,0,0,0.4);
  }
}
