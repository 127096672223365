.FarmerListing {

  height: 100%;
  overflow-y: auto;

  .container {
    margin: 0 auto;
    max-width: 80vw;
    position: relative;
    z-index: 10;

    .listing {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      margin-bottom: 24px;

      @media(max-width: 920px) {
        grid-template-columns: 1fr;
      }
    }
  }

  &.no-back-btn {
    .container {
      margin: 48px auto;
    }
  }
}
