@import './backgrounds';
.FarmerStoryDetail {
  display: grid;
  grid-template-columns: 36vw 1fr;
  height: 100%;
  overflow: hidden;

  .left-panel {
    background: #f3f3f3;
    box-shadow: 4px 0 12px rgba(0,0,0,0.2);
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    box-sizing: border-box;
    position: relative;

    .content {
      position: relative;
      z-index: 50;
      color: white;
      height: 100%;
      overflow-y: auto;
    }

    .backdrop {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
    }

    .illustration {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      z-index: 20;
      svg {
        opacity: 0.3;
        path {
          stroke: white;
        }
      }
    }

    .photo {
      margin: 0 24px;
      box-shadow: 0 3px 8px rgba(0,0,0,0.2);
      img {
        display: block;
        width: 100%;
      }
    }
    .CountryCrop {
      margin: 24px;
      justify-content: center;
      .country {
        margin-right: 24px;
      }
      .icon {
        width: 32px;
        height: 32px;
        box-shadow: 1px 1px 2px rgba(0,0,0,0.4);
      }
      .name {
        font-size: 1.1em;
        text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
      }
    }
    .farmer-name {
      font-size: 1.5em;
      font-weight: 600;
      text-align: center;
      margin: 0 24px;
      text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
    }
    .summary {
      opacity: 0.8;
      font-size: 1.1em;
      font-style: italic;
      margin: 24px 24px 0;
      text-align: center;
      text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
    }
  }
  .summary, .story {
    padding: 0 ;
    font-size: 0.9em;
    h1, h2, h3, h4, h5, h6 {
      font-size: 1.2em;
      font-weight: 600;
      line-height: 1.3;
      margin: 0 0 1em;
    }

    p {
      margin: 0 0 1em;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .story {
    position: relative;
    z-index: 10;
    box-sizing: border-box;
    height: 100%;
    overflow: auto;
    padding: 24px;
    .crop {
      width: 200px;
      margin: 24px 0;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .Footer {
    top: 24px;
    bottom: auto;
    right: 24px;
    z-index: 1;
  }

  $len: length($backgrounds);

  @for $i from 1 through length($backgrounds) {
    $background: nth($backgrounds, $i);
    $color: nth($colors, $i);
    &.story-index-#{$i - 1} {
      .left-panel {
        .backdrop {
          background: $background;
          background-size: cover;
          transform: scaleX(-1);
        }
      }

      .story {
        h5 {
          color: $color;
        }
      }

      .Footer {
        color: $color;
      }
    }
  }

  &.no-back-btn {
    .left-panel .photo {
      margin-top: 24px;
    }
  }
}
