@import './backgrounds';

.FarmerListingCard {
  border-radius: 24px;
  padding: 24px;
  display: flex;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);

  $len: length($backgrounds);

  @for $i from 1 through length($backgrounds) {
    $c: nth($backgrounds, $i);
    &:nth-child(#{$len}n+#{$i}) {
      background: $c;
      background-size: cover;
      color: white;
    }
  }

  .photo {
    overflow: hidden;
    margin-bottom: 12px;
    border-radius: 8px;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.4);

    img {
      width: 100%;
      display: block;
    }
  }

  .details {
    flex-grow: 1;

    .CountryCrop {
      margin-bottom: 12px;
      .icon {
        box-shadow: 1px 1px 2px rgba(0,0,0,0.4);
      }
      .name {
        text-shadow: 1px 1px 1px black;
      }
    }

    .farmer-name {
      font-size: 1.3em;
      font-weight: 600;
      margin-bottom: 12px;
      text-shadow: 1px 1px 1px black;
    }
    .summary {
      opacity: 0.8;
      text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
    }
  }
}
