.Back {
  margin: 0 0 0 -4px;
  display: flex;
  align-items: center;
  border: none;
  background: none;
  font-family: inherit;
  font-size: 1rem;
  position: sticky;
  top: 0;

  // more hit box
  padding: 24px;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    margin-top: 1px;
  }
  span {
    font-weight: 600;
  }

  &.light {
    color: white;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
    svg {
      path {
        fill: white;
      }
    }
  }
}
