.CountryListing {
  text-align: center;
  height: 100%;
  overflow-y: auto;
  .listing {
    position: relative;
    z-index: 10;
  }
  .region-country {
    margin: 0 0 10vh;
    .region-name {
      font-size: 2.5em;
      font-weight: 600;
      margin-bottom: 10vh;
      padding: 12px 24px;
      color: white;
    }
    .countries {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 80vw;
      margin: 0 auto;
      .country {
        margin: 24px;
        .flag {
          width: 150px;
          height: 150px;
          box-shadow: 0 2px 8px rgba(0,0,0,0.3);
          border-radius: 50%;
          overflow: hidden;
          svg {
            width: 100%;
            height: 100%;
          }
        }
        .name {
          margin-top: 8px;
          font-weight: 600;
          font-size: 1.2em;
          background: white;
          display: inline-block;
          padding: 8px 12px;
        }
      }

    }
  }
}
