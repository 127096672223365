.SearchPanel {
  position: absolute;
  right: 24px;
  top: 90px;
  z-index: 10;
  width: 400px;
  color: black;
  border-radius: 12px;
  overflow: auto;
  max-height: 40vh;

  .empty {
    padding: 24px;
    opacity: 0.4;
    text-align: center;
  }

  .results {
    .farmer {

      transition: all 0.2s;

      &.active {
        background: var(--theme);
        color: white;
        opacity: 1;
        .info {
          opacity: 1;
        }
        .country svg {
          fill: white;
        }
      }

      display: flex;
      align-items: flex-start;
      border-bottom: 1px dashed  var(--theme);
      padding: 16px;
      &:last-child {
        border-bottom: none;
      }
      .crop-image {
        flex: 0 0 40px;
        //border: 1px solid #ccc;
        //background: rgba(251, 154, 10, 0.5);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        overflow: hidden;
        svg {
          width: 40px;
          height: 40px;
          path {
            stroke: white!important;
          }
        }
      }
      .info {
        margin-left: 14px;
        opacity: 0.6;
        font-size: 0.9em;
        .name-country {
          margin-bottom: 8px;
          .name {
            font-weight: 600;
          }
          .country-crop {
            font-size: 0.9em;
            display: flex;
            .country {
              margin-left: -5px;
              svg {
                transition: all 0.2s;
                width: 18px;
                float: left;
              }
            }

            .crop {
              &:before {
                content: '-';
                margin: 0 4px;
              }
            }
          }
        }
        .bio {
          font-size: 0.9em;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
