.Pin {
  position: absolute;
  cursor: pointer;
  transition: all 0.2s;

  .name {
    position: absolute;
    top: -55px;
    white-space: nowrap;
    left: 50%;
    background: white;
    box-shadow: 0 0 8px rgba(0,0,0,0.2);
    border-radius: 4px;
    padding: 4px 6px;
    line-height: 1.2;
    font-size: 0.8em;
  }

  .marker {
    transform: rotate(-45deg);
    transform-origin: 50% 50%;
    border-radius: 50% 50% 50% 0;
    padding: 0 3px 3px 0;
    width: 30px;
    height: 30px;
    background: #ed1f34;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -23px 0 0 -16px;
    -webkit-box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
    -moz-box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
    box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
  }

  .marker:after {
    content: '';
    width: 18px;
    height: 18px;
    margin: 7px 0 0 8px;
    background: #ffffff;
    position: absolute;
    border-radius: 50%;
  }

  .beacon {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    -webkit-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: -1
  }

  .beacon {
    .before,
    .after {
      position: absolute;
      content: "";
      height: 30px;
      width: 30px;
      left: 0;
      top: 0;
      background-color: transparent;
      border-radius: 50%;
      box-shadow: 0 0 0 2px #e8710e;
      -webkit-animation: active 2s infinite linear;
      -moz-animation: active 2s infinite linear;
      animation: active 2s infinite linear;
    }
    .after {
      -moz-animation-delay: 1s;
      -webkit-animation-delay: 1s;
      animation-delay: 1s;
    }
  }

  @-webkit-keyframes active {
    0% {
      transform: scale(.1);
      opacity: 1;
    }
    100% {
      transform: scale(2.5);
      opacity: 0;
    }
  }

  @keyframes active {
    0% {
      transform: scale(.1);
      opacity: 1;
    }
    100% {
      transform: scale(2.5);
      opacity: 0;
    }
  }

  //
  //$one: #ed1f34;
  //$two: #0C67FE;
  //$three: #FC960A;
  //
  //&:nth-child(1n) {
  //  .marker {
  //    background: $one;
  //  }
  //  .beacon:after, .beacon:before {
  //    box-shadow: 0 0 0 2px $one;
  //  }
  //}
  //
  //&:nth-child(2n) {
  //  .marker {
  //    background: $two;
  //  }
  //  .beacon:after, .beacon:before {
  //    box-shadow: 0 0 0 2px $two;
  //  }
  //}
  //
  //&:nth-child(3n) {
  //  .marker {
  //    background: $three;
  //  }
  //  .beacon:after, .beacon:before {
  //    box-shadow: 0 0 0 2px $three;
  //  }
  //}
}
