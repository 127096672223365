.Map {
  position: fixed;
  overflow: hidden;

  // Change at the Map.tsx:81 too
  //left: -3vw;
  //top: 10vh;

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--theme) url("../../assets/backgrounds/orange.jpg") no-repeat;
  background-size: cover;
  background-position: center center;
  //transform: translateY(60px);
  //transform: scale(1.2) translateX(-40px);

  .click-overlay {
    position: absolute;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .loading-box {
    display: none;
    z-index: 600;
    position: fixed;
    left: 50%;
    top: 50%;
    margin-top: -28px;
    margin-left: -90px;
    width: 180px;
    background: white;
    text-align: center;
    padding: 16px;
    border-radius: 32px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.2);
  }

  .internal {
    position: relative;
    top: 10%;
    left: -3%;
    width: 100%;
    height: 100%;
    transition: all 0.2s;
    //transform: scale(1.2);
    //transform: translateX(-5vh);
    //top: 5vh;
    .pins {
      position: absolute;
      z-index: 20;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
    svg.map {
      z-index: 10;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      transform: scaleY(1.3);
      path {
        fill: rgba(255,255,255,0.5);
      }
    }
  }

  &.hide-pin {
    .pins {
      display: none;
    }
  }

  &.loading {
    .loading-box {
      display: block;
    }
  }

  &.loading .internal{
    opacity: 0.3;
  }
}
