.Overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0);
  transition: background 0.2s;

  &.entered {
    background: rgba(0, 0, 0, 0.6);
  }
}
