.CountryCrop {
  display: flex;
  .country, .crop {
    display: flex;
    align-items: center;
  }
  .name {
    font-size: 0.9em;
  }
  .icon {
    margin-top: 2px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 8px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .country {
    margin-right: 12px;
  }
  .crop {
    .icon {
      background: white;
      svg path {
        stroke-width: 2px;
      }
    }
  }

  &.inverse-crop {
    .crop .icon svg path {
      stroke: white;
    }
  }
}
