.Search {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 400;
  .SearchInput {
    position: absolute;
    right: 24px;
    top: 24px;
    z-index: 100;
    input {
      font-family: inherit;
      font-size: 1rem;
      border-radius: 32px;
      background: rgba(255,255,255,0.9);
      border: none;
      width: 250px;
      transition: width 0.2s;
      padding: 12px 12px 12px 18px;
      color: #da7a0c;
      &::placeholder {
        color: #eeaf6a;
      }
      &:focus, &:active, &.active {
        width: 400px;
        box-shadow: 0 0 10px rgba(0,0,0,0.2);
      }
    }
  }

  &.active {
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    .overlay {
      position: absolute;
      background: rgba(0,0,0,0.6);
      //backdrop-filter: blur(4px);
    }
  }
}
