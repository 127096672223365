.CropsFilter {
  position: fixed;
  z-index: 30;
  bottom: 120px;
  left: 112px;
  max-width: 70vw;
  max-height: 70vh;
  background: rgba(255,255,255,0.8);
  border-radius: 12px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  color: black;
  padding: 8px;
  backdrop-filter: blur(8px);
  overflow: auto;

  .crops-listing {
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    grid-auto-flow: column;
    gap: 8px;
    .crop {
      display: flex;
      align-items: center;
      background: white;
      border-radius: 32px;
      padding: 8px;
      border: 1px dashed #ccc;
      transition: all 0.2s;
      .icon {
        flex: 0 0 32px;
        width: 32px;
        height: 32px;
        background: white;
        border-radius: 50%;
        overflow: hidden;
        box-sizing: border-box;
        border: 1px solid transparent;
        svg {
          width: 100%;
          height: 100%;
          path {
            stroke: white;
            stroke-width: 2px;
          }
        }
      }
      .name {
        flex-grow: 1;
        text-align: center;
        font-size: 0.9em;
        font-weight: 600;
        padding: 0 12px;
      }

      &.active {
        opacity: 1;
        background: var(--theme);
        border: 1px solid white;
        color: white;
        .icon {
          border: 1px solid white;
        }
      }
    }
  }
}
