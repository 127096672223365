.Footer {
  position: absolute;
  bottom: 24px;
  right: 24px;
  line-height: 1.2;
  z-index: 1;
  color: white;
  &.dark {
    color: var(--theme);
  }
  font-size: 1.3em;
  text-align: right;
  opacity: 0.7;
  font-weight: 300;
  .important {
    font-weight: 600;
  }
}