.PickerButton {
  position: fixed;
  bottom: 24px;
  width: 56px;
  height: 56px;
  background: rgba(255,255,255,0.2);
  z-index: 200;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 12px rgba(0,0,0,0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 6px solid rgba(255,255,255,0.1);
  transition: all 0.2s;
  svg {
    width: 32px;
    height: 32px;
    fill: rgba(255,255,255,0.6);
  }
  &.active {
    border: 6px solid white;
    background: rgba(255,255,255,0);
    svg {
      width: 32px;
      height: 32px;
      fill: white;
    }
  }
}
